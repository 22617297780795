import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const navUl = styled.ul`
  /* display: inline-block; */
  overflow: hidden;
  margin-bottom: 2110;
  padding: 0;
`;

export default function Nav() {
  return (
    <nav>
      <navUl>
        <li className="navItem">
          <Link className="navItem" to="/">
            Home
          </Link>
        </li>
        <li className="navItem">
          <Link className="navItem" to="/work/">
            Work
          </Link>
        </li>
      </navUl>
    </nav>
  );
}
